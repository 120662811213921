import { useGlobalDispatch, useGlobalState } from '~/context/global';
import { HealthReportAction } from '~/context/global/reports/actions';
import {
  submitWellReport,
  submitUnwellReport,
  WellReportRequestBody,
  UnwellReportRequestBody,
} from '~/requests/reports';
import {
  WellSubmissionIds,
  UnwellReportResponseData,
} from '~/context/global/reports/types';
import {
  HealthStatus,
  HeadOfHouseholdHealthStatus,
  HeadOfHouseholdHealthReport,
  HouseholdMembersHealthReport,
} from '~/context/global/reports/householdReport';

interface UseHouseholdHealthReport {
  submitHouseholdWellReports: (
    recaptchaToken: string
  ) => Promise<WellSubmissionIds>;
  submitHouseholdUnwellReport: (
    unwellReport: UnwellReportRequestBody,
    recaptchaToken: string
  ) => Promise<UnwellReportResponseData>;
}

export const useHouseholdHealthReport = (): UseHouseholdHealthReport => {
  const dispatch = useGlobalDispatch();
  const {
    headOfHousehold,
    householdMembers,
  } = useGlobalState().healthReport.household;

  const groupWellReports = (
    headOfHouseholdHealthReport: HeadOfHouseholdHealthReport,
    householdMembersHealthReport: HouseholdMembersHealthReport
  ): WellReportRequestBody[] => {
    const wellReports: WellReportRequestBody[] = [];

    if (
      headOfHouseholdHealthReport.healthStatus ===
      HeadOfHouseholdHealthStatus.WELL
    ) {
      wellReports.push(headOfHouseholdHealthReport.wellReport);
    }

    const membersWellReports = Object.values(householdMembersHealthReport)
      .filter(member => member.healthStatus === HealthStatus.WELL)
      .map(member => member.wellReport);

    wellReports.push(...membersWellReports);
    return wellReports;
  };

  const submitHouseholdWellReports = async (
    recaptchaToken: string
  ): Promise<WellSubmissionIds> => {
    const wellReports = groupWellReports(headOfHousehold, householdMembers);
    if (!wellReports.length) {
      return;
    }

    const { status, data } = await submitWellReport(
      wellReports,
      recaptchaToken
    );

    if (status !== 200) {
      throw new Error('Response status was ' + status);
    }

    dispatch({
      type: HealthReportAction.STORE_HEAD_OF_HOUSEHOLD_WELL_SUBMISSION_ID,
      payload: { wellSubmissionId: data.userSubmissionId },
    });

    dispatch({
      type: HealthReportAction.STORE_HOUSEHOLD_MEMBER_WELL_SUBMISSION_IDS,
      payload: {
        householdMemberSubmissionIds: data.householdMemberSubmissionIds,
      },
    });

    return {
      userSubmissionId: data.userSubmissionId,
      householdMemberSubmissionIds: data.householdMemberSubmissionIds,
    };
  };

  const submitHouseholdUnwellReport = async (
    unwellReport: UnwellReportRequestBody,
    recaptchaToken: string
  ): Promise<UnwellReportResponseData> => {
    const { data, status } = await submitUnwellReport(
      unwellReport,
      recaptchaToken
    );

    if (status !== 200) {
      throw new Error('Response status was ' + status);
    }

    return data;
  };

  return {
    submitHouseholdWellReports,
    submitHouseholdUnwellReport,
  };
};
