/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import * as Styled from './styles';

interface Props {
  onRetryButtonClick: () => void;
}

export const Retry: React.FC<Props> = ({ onRetryButtonClick }) => {
  const { t } = useTranslation();

  return (
    <Styled.PageContainer>
      <Styled.Container>
        <Styled.PageTitle>{t('HouseholdReport.retryMessage')}</Styled.PageTitle>
      </Styled.Container>
      <Styled.Container>
        <Grid container direction="column">
          <Button
            color="primary"
            variant="contained"
            onClick={onRetryButtonClick}
          >
            {t('HouseholdReport.button.retry')}
          </Button>
        </Grid>
      </Styled.Container>
    </Styled.PageContainer>
  );
};
