import React from 'react';
import { PageProps } from 'gatsby';
import { withAuthGate } from '~/HOC/AuthGate';
import { HouseholdHealthStatus } from '~/context/global/reports/types';
import { HouseholdReportSubmission } from '~/components/HouseholdReportSubmission';
import SEO from '~/components/seo';

interface Props extends PageProps {
  location: Location & {
    state: {
      householdHealthStatus?: HouseholdHealthStatus;
    };
  };
}

const HouseholdReportSubmissionPage: React.FC<Props> = props => {
  const householdHealthStatus = props?.location?.state?.householdHealthStatus;
  const reportType =
    householdHealthStatus === HouseholdHealthStatus.WELL ? 'well' : 'unwell';
  const title = `HouseholdVaccination.pageTitle.${reportType}`;

  return (
    <>
      <SEO title={title} pageContext={props.pageContext} />
      <HouseholdReportSubmission {...props} />
    </>
  );
};

export default withAuthGate(HouseholdReportSubmissionPage);
