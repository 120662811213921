/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '~/state/hooks/useNavigate';
import { logger, ONMFeature } from '~/services/logging';
import { useAccountState } from '~/state/hooks/useAccountState';
import { useHouseholdHealthReport } from '~/state/hooks/useHouseholdHealthReport';
import {
  HouseholdHealthStatus,
  WellSubmissionIds,
} from '~/context/global/reports/types';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { LoadingPage } from '~/components/Loading';
import { Retry } from './Retry';

interface Props {
  location?: Location & {
    state: {
      recaptchaToken?: string;
      householdHealthStatus?: HouseholdHealthStatus;
    };
  };
}

export const HouseholdReportSubmission: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [error, setError] = useState<boolean>(false);
  const { fetchAndUpdateAccountState } = useAccountState();
  const { submitHouseholdWellReports } = useHouseholdHealthReport();

  const recaptchaToken = location?.state?.recaptchaToken;
  const householdHealthStatus = location?.state?.householdHealthStatus;
  const isHouseholdUnwell =
    householdHealthStatus === HouseholdHealthStatus.UNWELL;

  const startUnwellFlow = () => navigate('unwell/symptoms');

  const gotToBonusQuestions = (wellSubmissionIds: WellSubmissionIds) => {
    if (wellSubmissionIds.householdMemberSubmissionIds.length) {
      navigate('household-bonus-questions', {
        replace: true,
        state: { wellSubmissionIds },
      });
    } else {
      navigate('bonus-questions', {
        replace: true,
        state: { wellSubmissionId: wellSubmissionIds.userSubmissionId },
      });
    }
  };

  const proceed = (wellSubmissionIds: WellSubmissionIds) => {
    return isHouseholdUnwell
      ? startUnwellFlow()
      : gotToBonusQuestions(wellSubmissionIds);
  };

  const submitWellReports = async () => {
    if (error) setError(false);

    try {
      const submissionIds = await submitHouseholdWellReports(recaptchaToken);
      await fetchAndUpdateAccountState();
      proceed(submissionIds);
    } catch (error) {
      const errorCode =
        error?.response?.data?.errorCode ?? 'HouseholdWellSubmissionsError';
      logger.error(ONMFeature.HOUSEHOLD_WELL_REPORT, error);
      showSnackbar(t(`ErrorHandling.submissionErrors.${errorCode}`), 'error');
      setError(true);
    }
  };

  useEffect(function checkFlowValidity() {
    if (!recaptchaToken) {
      navigate('', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (recaptchaToken && !error) {
      submitWellReports();
    }
  }, []);

  if (error) {
    return <Retry onRetryButtonClick={submitWellReports} />;
  }

  return <LoadingPage />;
};
